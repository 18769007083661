/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "tr";
export const localeOptions = [
  { id: "tr", name: "Türkçe", direction: "ltr", dateFormat: "DD.MM.YYYY", dateFormatWithTime: "DD.MM.YYYY - h:i" },
  { id: "en", name: "English", direction: "ltr", dateFormat: "MM/DD/YYYY", dateFormatWithTime: "MM/DD/YYYY - h:i" },
];

export const searchPath = "/app/pages/search";

export const imagePath = process.env.REACT_APP_PUBLIC_PATH + "/images";
export const filePath = process.env.REACT_APP_PUBLIC_PATH + "/files";

export const defaultColor = "dark.blue";
export const defaultDirection = "ltr";
export const themeColorStorageKey = "__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
